<template>
  <div>
    <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
      <div class="col-span-1 p-4">
        <div class="opacity-75 text-sm font-medium mb-1">Credit Card Limit</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          <!-- {{ calculatedValue }} -->
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="opacity-75 text-sm font-medium mb-1">Available Balance</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          Available
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
      <div class="col-span-1 p-4">
        <div class="opacity-75 text-sm font-medium mb-1">Available Credit</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          credit
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="opacity-75 text-sm font-medium mb-1">Utilization Rate</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          util
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
      <div class="col-span-1 p-4">
        <div class="opacity-75 text-sm font-medium mb-1">
          Average Utilization Rate
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          average
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="opacity-75 text-sm font-medium mb-1">Card Plan</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          plan
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
      <div class="col-span-1 p-4">
        <div class="opacity-75 text-sm font-medium mb-1">
          Card Plan Start Date
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          date
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="opacity-75 text-sm font-medium mb-1">
          Card Plan End Date
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          end
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
      <div class="col-span-1 p-4">
        <div class="opacity-75 text-sm font-medium mb-1">Account Number</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          acc/no
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
      <div class="col-span-1 p-4">
        <div class="opacity-75 text-sm font-medium mb-1">Annual Plan Fee</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          plan
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="opacity-75 text-sm font-medium mb-1">Status</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          status
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // components: {
  //   WalletCard: () => import('./WalletCard'),
  // },
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      requestError: '',

      cardDetails: null,
    };
  },
  beforeMount() {
    this.getCardDetails();
  },
  methods: {
    async getCardDetails() {
      await this.$get({
        url: `${this.$baseurl}/cards/admin/cards?userId=${this.staff?.id}`,
        headers: this.headers,
        success: (response) => {
          this.cardDetails = response.data.data;
        },
        error: () => {
          this.requestError = 'there was an issue please try again later';
        },
      });
    },
  },
  computed: {
    calculatedValue() {
      return this.cardDetails.data.items.filter(
        (each) => each.userId == this.staff?.id
      );
    },
  },
};
</script>
